import React from "react";
import { Row, Col, Container } from "reactstrap";
import { Form, Field, FormSpy } from "react-final-form";
import * as yup from "yup";
import { Button } from "react-bootstrap";

import Layout from "../../../layouts";
import Seo from "../../../components/Seo";
import IntroBanner from "../../../components/IntroBannerRevamp";
import Banner from "../../../images/services/devops/devops-banner.jpg";
import ContrastSection from "../../../components/ContrastSection";
import { sendEmail } from "../../../services/contact";
import { generateDownload } from "../../../utils/helperFunctions";

// Icons
import ExpertiseAWS from "../../../images/service-icons/aws/expertise.svg";
import InnovationAWS from "../../../images/service-icons/aws/innovation.svg";
import CustomisedAWS from "../../../images/service-icons/aws/custom.svg";
import CostAWS from "../../../images/service-icons/aws/cost.svg";

// Clients
import Pokitpal from "../../../images/services/logo/pokitpal.png";
import GigaClear from "../../../images/services/logo/gigaclear.png";
import Solcast from "../../../images/home-page/logos-png/Solcast.png";

// Casestudy
import downloadFile from "../../../documents/Case-Study-AWS-Solcast.pdf";
import downloadFile2 from "../../../documents/Case-Study-AWS-pokitpal.pdf";
import caseStudyImgOne from "../../../images/services/devops/solocast-casestudy.jpg";
import caseStudyImgTwo from "../../../images/services/devops/pokitpal-casestudy.jpg";
import CasestudyTwo from "../../../components/CasestudyTwo";

// Testimonial
import TabcmpSet from "../../../components/tab/TabcmpSet";
import ClientTestimonial3 from "../../../components/ClientTestimonialGaryPokitpal";
import GaryCobain from "../../../images/Clients/GaryCobain.jpeg";

// Contact
import UserCard from "../../../components/UserCard";
import SvgIconsSet from "../../../components/SvgIconsSet";
import Chamal from "../../../images/services/devops/Chamal.png";

const AWS = () => {
  const servicesList = [
    {
      id: "1",
      title: "Applications Deployment",
      textbold: "Struggling with Slow Deployments?",
      textdetail:
        "Streamline your application deployment processes with Applications Deployment services. Achieve seamless continuous integration and delivery across multiple environments, ensuring minimal downtime and increased productivity.",
    },
    {
      id: "2",
      title: "Database Services",
      textbold: "Database Performance Issues?",
      textdetail:
        "Optimise and secure your databases with DataOps Services. We provide comprehensive database management, performance tuning, backups, and disaster recovery to ensure your data is always accessible and safe.",
    },
    {
      id: "3",
      title: "Cloud Cost Optimisation",
      textbold: "Cloud Costs Out of Control?",
      textdetail:
        "Optimise your cloud expenses with Cloud Cost Optimisation services. Our experts analyse your usage patterns and implement cost-saving strategies to help you maximise your budget without sacrificing performance.",
    },
    {
      id: "4",
      title: "Managed Cloud Services",
      textbold: " Overwhelmed by Cloud Management?",
      textdetail:
        "Experience comprehensive cloud management with Managed Cloud Solutions. From routine maintenance to security updates, we handle all aspects of your cloud infrastructure, so you can focus on your core business.",
    },
    {
      id: "5",
      title: "Cloud Consulting Services",
      textbold: "Need Expert Cloud Advice?",
      textdetail:
        "Get expert guidance and strategic consulting with Cloud Consultancy services. Our services help you design, implement, and optimise cloud solutions tailored to your specific business needs.",
    },
    {
      id: "6",
      title: "Cloud Monitoring and Performance Management",
      textbold: "Cloud Performance Sluggish? ",
      textdetail:
        "Ensure peak performance of your cloud infrastructure with Cloud Performance Optimisations services. We provide continuous monitoring, alerting, and performance tuning to keep your systems running efficiently and effectively.",
    },
  ];
  const tabData = [
    {
      label: "Gary Cobain",
      text: "PokitPal",
      image: GaryCobain,
      content: <ClientTestimonial3 />,
    },
  ];

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is a required field"),
  });

  const validate = (values) => {
    const errors = {};
    try {
      schema.validateSync(values, { abortEarly: false });
    } catch (err) {
      err.inner.forEach((error) => {
        errors[error.path] = error.message;
      });
    }
    return errors;
  };

  const onSubmitHandler = async (values) => {
    // eslint-disable-next-line no-undef
    generateDownload(document, [downloadFile, downloadFile2]);

    const formData = { ...values };
    formData.Message =
      "\nCASE STUDY 1: HOW A CLOUD-SAVVY PARTNER HELPED SOLCAST CUT AWS COSTS BY 20%\nCASE STUDY 2: HOW A FINTECH COMPANY REDUCED CLOUD SPENDING BY 40% WITH THE RIGHT SUPPORT";
    formData.FormType = "CaseStudy";
    formData.Subject = "CASE STUDY: DEVOPS";

    try {
      await sendEmail(formData);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  return (
    <Layout bodyClass="service-page devops-service">
      <Seo
        title="DevOps Services"
        description="iTelaSoft provides a range of DevOps services to companies across all industries. Our experts have helped organisations on cloud-native restructuring, cloud cost optimisation, cloud consultancy and many more ways, to optimise business performance and increase long-term profitability"
      />

      <IntroBanner
        title="DevOps Services"
        subtitle="Innovate faster, deploy smarter with DevOps"
        image={Banner}
      />

      <div className="content">
        <ContrastSection
          title="Professional DevOps Services delivered by experts."
          para="iTelaSoft provides a range of DevOps services to companies across all industries. Our experts have helped organisations on cloud-native restructuring, cloud cost optimisation, cloud consultancy and many more ways, to optimise business performance and increase long-term profitability"
        />
      </div>

      <section>
        <Container className="mt-4">
          <div className="title title-1">
            Why an Independent Partner is Important?
          </div>
          <div className="sub-title">
            At iTelaSoft, we deliver tailored, expert DevOps solutions that
            enhance efficiency, ensure robust performance, and drive your
            business success.
          </div>
          <Row className="mt-2">
            <Col>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={ExpertiseAWS} alt="Icon" />
                </div>
                <div className="text">
                  <strong>Expertise and Experience:</strong>
                  <p>
                    {" "}
                    Leverage our extensive knowledge and proven experience in
                    DevOps practices to optimise your operations.
                  </p>
                </div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={CustomisedAWS} alt="Icon" />
                </div>
                <div className="text">
                  <strong>Customised Solutions:</strong>
                  <p>
                    Customised solutions designed to meet your unique business
                    requirements and goals.
                  </p>
                </div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={CostAWS} alt="Icon" />
                </div>
                <div className="text">
                  <strong>Cost Optimisation:</strong>
                  <p>
                    Through careful planning, resource allocation, and
                    implementation of cost-saving strategies, help clients
                    achieve maximum ROI for the investments.
                  </p>
                </div>
              </div>
              <div className="icon-with-text-new">
                <div className="icon-container">
                  <img src={InnovationAWS} alt="Icon" />
                </div>
                <div className="text">
                  <strong>Proven Track Record:</strong>
                  <p>
                    Our successful projects and satisfied clients demonstrate
                    our ability to deliver results that exceed expectations.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pb-0">
        <Container className="service-container">
          <Row>
            <Col>
              <div>
                <div className="title title-1">DevOps Services</div>
              </div>
              <div className="mt-4">
                {servicesList.map((service) => (
                  <div className="wrapper-service" key={service.id}>
                    <div className="service-details">
                      <div className="service-title">{service.title}</div>
                      <div className="service-content ml-2">
                        <div className="service-breif pb-1">
                          {service.textbold}
                        </div>
                        <div className="service-description">
                          {service.textdetail}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="py-0">
        <CasestudyTwo
          image1={caseStudyImgOne}
          title1="How a Cloud-Savvy Partner Helped Solcast Cut AWS Costs by 20%"
          text1="Discover how iTelaSoft's expert guidance paved the way for Solcast to slash AWS costs by 20%, ensuring sustained growth and success in their cloud endeavors."
          download1={null}
          image2={caseStudyImgTwo}
          title2="How a fintech company reduced cloud spending by 40% with the right support"
          text2="Learn how iTelaSoft's expertise enabled a fintech company to reduce cloud spending by 40%, fostering sustained growth and success in their AWS journey."
          download2={null}
        />
      </section>

      <section className="pt-0">
        <div className="section-padding wrap-contrast-section download-portfolio consultation wrap-case-study">
          <Container>
            <div className="contrast-section">
              <div className="">
                <div className="">
                  <div className="">
                    <div className="row justify-content-center">
                      <div className="flex-column">
                        <div className="form-segment pb-3 mx-1">
                          <p>
                            <b>Download the Case Studies Now!</b>
                          </p>
                          <Form
                            onSubmit={onSubmitHandler}
                            validate={validate}
                            render={({ handleSubmit }) => (
                              <form
                                className="case-study-email-form d-flex flex-sm-row flex-column mb-1"
                                onSubmit={handleSubmit}
                              >
                                <Field name="email">
                                  {({ input, meta }) => (
                                    <div className="email-area col-lg-6 col-md-6 col-sm-6 col-12 pr-1 pl-0">
                                      <input
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...input}
                                        type="email"
                                        placeholder="Enter your e-mail address"
                                      />
                                      {meta.error && meta.touched && (
                                        <span className="error-val">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                                <FormSpy
                                  subscription={{
                                    hasValidationErrors: true,
                                    submitting: true,
                                  }}
                                >
                                  {(subscriptions) => (
                                    <Button
                                      className={`btn-icon-button mt-1 mt-sm-0 mb-2 ${
                                        subscriptions.hasValidationErrors ||
                                        subscriptions.submitting
                                          ? "disabled-button"
                                          : ""
                                      }`}
                                      variant="primary"
                                      type="submit"
                                    >
                                      <span className="btn-text">
                                        Download Case Studies
                                      </span>
                                      <span className="btn-icon">
                                        <SvgIconsSet.SideArrow />
                                      </span>
                                    </Button>
                                  )}
                                </FormSpy>
                              </form>
                            )}
                          />
                          <p className="small-text">
                            By downloading these case studies, you agree to
                            receive emails from iTelaSoft about products,
                            services, and updates. You can unsubscribe at any
                            time.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>

      <section className="section-our-partners">
        <Container>
          <Row>
            <Col>
              <div id="who-depend-on-us">
                {" "}
                <div className="title-1 mb-2">Who Depends on Us? </div>
                <div className="section-discription">
                  Discover some of the esteemed organisations iTelaSoft has had
                  the privilege to serve. From startups to enterprise-level
                  companies, our clients trust us to deliver innovative
                  solutions and exceptional service, driving their success in
                  the AWS cloud.
                </div>
                <div className="section-our-partners-logo-set wso2-partners mb-4">
                  <a
                    href="https://www.pokitpal.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="wrap-logo p-0">
                      <div className="partners-logo">
                        {" "}
                        <img src={Pokitpal} alt="" />
                      </div>
                    </div>
                  </a>
                  <a
                    href="https://solcast.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="wrap-logo p-0">
                      <div className="partners-logo">
                        {" "}
                        <img src={Solcast} alt="" />
                      </div>
                    </div>
                  </a>
                  <a
                    href="https://gigaclear.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="wrap-logo p-0">
                      <div className="partners-logo">
                        {" "}
                        <img src={GigaClear} alt="" />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="client-testimonial-section mb-4">
        <Container>
          <div className="tab-header-aws">
            <h3 className="title-1">What our client says</h3>
          </div>
          <TabcmpSet tabData={tabData} tabNum={1} />
        </Container>
      </section>

      <section className="section-contact">
        <Container>
          <Row>
            <Col md={9} lg={9} xl={9} className="title-section flex-column">
              <div className="title title-1 desk">Let us know how to help</div>
              <div className="title title-1 mobile">
                Let us know how to help
              </div>
              <div>
                <p>
                  We love to hear what challenges you go through in your
                  business and discuss how we can assist you.
                </p>
              </div>
              <div className="getting-touch-right">
                <a className="btn btn-primary btn-icon-button" href="/contact/">
                  <span className="btn-text">Get in touch</span>
                  <span className="btn-icon">
                    <SvgIconsSet.SideArrow />
                  </span>
                </a>
              </div>
            </Col>
            <Col
              md={3}
              lg={3}
              xl={3}
              className="mb-2 contact-card-section col-6"
            >
              <a
                className="contact-card"
                href="/contact/"
                aria-label="Chamal Fernando"
              >
                <UserCard
                  name="Chamal Fernando"
                  detail="Cloud/Infrastructure Adviser"
                  image={Chamal}
                  link="https://www.linkedin.com/in/chamal-fernando-3a355755/"
                />
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default AWS;
